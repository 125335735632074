<template>
  <div>
    <div>
      <div
        class="row home_game_arrow home_game_casino"
        :class="[m_show_id == '' || m_show_id == null ? 'g-flex-wrap' : '']"
      >
        <div class="d-flex justify-content-between align-items-center">
          <div class="game_logo_img fw-bolder my-3">
            <div class="subtitle_line"></div>
            <span class="text_span">
              {{ $t("text_money_hot").toUpperCase() }}</span
            >
          </div>
          <!-- <span
            v-if="m_show_id != '' && m_show_id != null"
            @click="this.$router.push('/' + Id)"
            class="see-all-btn text_span"
            >{{ $t("text_seeall") }}</span
          > -->
        </div>
        <div class="d-grid hot_mon_grid">
          <div v-for="l in 10" :key="l" class="home_game_img">
            <div class="home_game_img_div">
              <div :class="css_list" class="">
                <img
                  v-lazy="require('@/assets/img/money_hot/' + l + '.png')"
                  class="w-100"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- <swiper
          :loop="swiper_casino_options.loop"
          :speed="swiper_casino_options.speed"
          :slidesPerView="swiper_casino_options.slidesPerView"
          :spaceBetween="swiper_casino_options.spaceBetween"
          :breakpoints="swiper_casino_options.breakpoints"
          :navigation="swiper_casino_options.navigation"
          :allowTouchMove="m_show_id == '' || m_show_id == null ? false : true"
        >
          <swiper-slide v-for="l in 10" :key="l" class="home_game_img">
            <div class="home_game_img_div">
              <div :class="css_list" class="game_item">
                <img
                  v-lazy="require('@/assets/img/money_hot/' + l + '.png')"
                  class="w-100"
                />
              </div>
            </div>
          </swiper-slide>
        </swiper> -->
      </div>
    </div>
  </div>
</template>
<script>
// import { reactive } from "vue";
// //引入swiper
// import SwiperCore, { Autoplay, EffectCoverflow, Navigation } from "swiper";
// import { Swiper, SwiperSlide } from "swiper/vue";
// import "swiper/swiper.min.css";
// import "swiper/components/pagination/pagination.scss";
// //设置swiper
// SwiperCore.use([Autoplay, EffectCoverflow, Navigation]);

export default {
  // setup() {
  //   //指定swiper的设置
  //   let swiper_casino_options = reactive({
  //     loop: false,
  //     speed: 500,
  //     slidesPerView: "auto",
  //     navigation: {
  //       nextElRef: ".swiper-button-next",
  //       prevElRef: ".swiper-button-prev",
  //     },
  //   });

  //   return {
  //     swiper_casino_options,
  //   };
  // },
  components: {
    // Swiper,
    // SwiperSlide,
  },
  data() {
    return {
      css_list: "position-relative",
    };
  },
  methods: {},
  watch: {},
  computed: {},
  mounted() {},
  created() {},
};
</script>
