<template>
  <div>
    <div>
      <div
        class="row home_game_arrow home_game_casino"
        :class="[m_show_id == '' || m_show_id == null ? 'g-flex-wrap' : '']"
      >
        <div class="d-flex justify-content-between align-items-center">
          <div class="game_logo_img fw-bolder my-3">
            <div class="subtitle_line"></div>
            <span class="text_span">
              {{
                Id == "etf"
                  ? $t("text_stock1").toUpperCase()
                  : Id == "gamestock"
                  ? $t("text_hot_items").toUpperCase()
                  : Id == "sportstock"
                  ? $t("text_commision_items").toUpperCase()
                  : Id == "marketstock"
                  ? $t("text_stock5").toUpperCase()
                  : Id == "hotstock"
                  ? $t("text_stock4").toUpperCase()
                  : $t("text_monthly_pro").toUpperCase()
              }}</span
            >
          </div>
          <span
            v-if="m_show_id != '' && m_show_id != null"
            @click="this.$router.push('/' + Id)"
            class="see-all-btn text_span"
            >{{ $t("text_seeall") }}</span
          >
        </div>
        <swiper
          :loop="swiper_casino_options.loop"
          :speed="swiper_casino_options.speed"
          :slidesPerView="swiper_casino_options.slidesPerView"
          :spaceBetween="swiper_casino_options.spaceBetween"
          :breakpoints="swiper_casino_options.breakpoints"
          :navigation="swiper_casino_options.navigation"
          :allowTouchMove="m_show_id == '' || m_show_id == null ? false : true"
        >
          <swiper-slide v-for="l in which_item" :key="l" class="home_game_img">
            <div class="home_game_img_div">
              <div :class="css_list" class="game_item">
                <img v-lazy="l.img" class="w-100" />

                <div
                  class="p-xl-3 d-flex justify-content-around flex-wrap ps-1 pe-0 align-items-center py-3 flex-column"
                >
                  <h6
                    class="mb-1 text-invest d-flex flex-xl-row flex-column"
                    style="word-wrap: break-word"
                  >
                    {{ $t("invest_text3") }}：
                    <div>
                      <img
                        src="@/assets/img/main/diamond.png"
                        style="width: 25px; height: 25px !important"
                        class="no_filter"
                      />
                      {{ l.payment }}
                    </div>
                  </h6>
                  <h6
                    class="text-truncate mb-1 text-invest d-flex"
                    :class="
                      this.m_show_id != null
                        ? 'flex-xl-row flex-column'
                        : 'flex-column'
                    "
                  >
                    {{ Id != "etf" ? $t("text_rf") : $t("text_annual") }}：

                    <span
                      class="text-center d-flex justify-content-center no_btn_hover px-3"
                      :class="
                        l.rate > 0 ? 'bg-s-red' : l.rate < 0 ? 'bg-s-green' : ''
                      "
                    >
                      <span> {{ l.rate }} % </span>
                    </span>
                  </h6>
                </div>

                <div class="game-actions">
                  <span class="btn-play mb-4" @click="click_detail('buy', l)">{{
                    $t("invest_text6")
                  }}</span>
                  <span
                    class="btn-play btn-play1"
                    @click="click_detail('sell', l)"
                    >{{ $t("text_sell") }}</span
                  >
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>

    <finance
      v-if="flag_finance_page"
      :click_list="click_list"
      :action="action"
      @fun_flag_page="fun_flag_page"
    />

    <invest
      v-if="flag_invest_page"
      :click_list="click_list"
      :action="action"
      @fun_flag_page="fun_flag_page"
    />
  </div>
</template>
<script>
import Cookies from "js-cookie";
import { reactive } from "vue";
// import { mapActions, mapGetters } from "vuex";
//引入swiper
import SwiperCore, { Autoplay, EffectCoverflow, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.scss";
//设置swiper
SwiperCore.use([Autoplay, EffectCoverflow, Navigation]);

// import { stockList } from "@/components/Game/stockList";
import finance from "./finance.vue";
import invest from "./invest.vue";
export default {
  props: { m_show_id: String },
  setup() {
    //指定swiper的设置
    let swiper_casino_options = reactive({
      loop: false,
      speed: 500,
      slidesPerView: "auto",
      navigation: {
        nextElRef: ".swiper-button-next",
        prevElRef: ".swiper-button-prev",
      },
    });

    return {
      swiper_casino_options,
    };
  },
  components: {
    Swiper,
    SwiperSlide,
    finance,
    invest,
  },
  data() {
    return {
      css_list: "position-relative",
      flag_finance_page: false,
      flag_invest_page: false,
      action: "",
      stockList: [],
      click_list: [],
      etf_list: [],
      slot_list: [],
      sport_list: [],
      hot_list: [],
      casino_list: [],
      startup_list: [],
    };
  },
  methods: {
    etf_item: function () {
      let param = {
        action: "etf_item",
        body: {
          uid: localStorage.getItem("uid"),
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        var status = res.status;
        if (status == "success") {
          this.etf_list = res.data;
        }
      });
    },
    betstock_fun: function () {
      let param = {
        action: "betstock_item",
        body: {
          uid: localStorage.getItem("uid"),
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        var status = res.status;
        if (status == "success") {
          this.stockList = res.data;
          this.slot_list = this.temp_fun("GAME SHARE");
          this.sport_list = this.temp_fun("SPORT SHARE");
          this.hot_list = this.temp_fun("HOT SHARE");
          this.casino_list = this.temp_fun("CASINO SHARE");
          this.startup_list = this.temp_fun("START UP SHARE");
        }
      });
    },
    temp_fun: function (Gamestype) {
      return this.stockList.filter((i) => {
        return i["tag"] == Gamestype;
      });
    },
    click_detail: function (action, e) {
      if (Cookies.get("code") == null || Cookies.get("code") == "") {
        this.$store.commit("islogin", true);
      } else {
        this.action = action;
        this.click_list = e;
        if (this.Id == "etf") this.flag_finance_page = true;
        else this.flag_invest_page = true;
      }
    },
    fun_flag_page: function (e) {
      this.flag_finance_page = e;
      this.flag_invest_page = e;
    },
    change_fun: function () {
      if (this.Id == "etf") this.etf_item();
      else this.betstock_fun();
    },
  },
  watch: {
    Id(oldval, newval) {
      if (oldval == "etf" || newval == "etf") this.change_fun();
    },
  },
  computed: {
    Id: function () {
      if (
        this.$route.params.id != null &&
        this.$route.params.id != "Login" &&
        this.$route.params.id != "Reg"
      )
        return this.$route.params.id;
      else if (this.m_show_id != null) return this.m_show_id;
      else return "";
    },
    which_item: function () {
      if (this.Id == "etf") return this.etf_list;
      else if (this.Id == "gamestock") return this.slot_list;
      else if (this.Id == "sportstock") return this.sport_list;
      else if (this.Id == "hotstock") return this.hot_list;
      else if (this.Id == "marketstock") return this.casino_list;
      else if (this.Id == "startupstock") return this.startup_list;
      else return "";
    },
  },
  mounted() {},
  created() {
    this.change_fun();
  },
};
</script>
